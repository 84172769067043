import dayjs from 'dayjs';
const pickerOptions = {
    shortcuts: [{
        text: '最近一周',
        onClick(picker) {
            const DAYS_A_WEEK = 7;
            const start = dayjs().startOf('day').subtract(DAYS_A_WEEK - 1, 'd').toDate();
            const end = dayjs().endOf('day').toDate();
            picker.$emit('pick', [start, end]);
        }
    }, {
        text: '最近一个月',
        onClick(picker) {
            const start = dayjs().startOf('day').subtract(1, 'M').toDate();
            const end = dayjs().endOf('day').toDate();
            picker.$emit('pick', [start, end]);
        }
    }, {
        text: '最近三个月',
        onClick(picker) {
            const start = dayjs().startOf('day').subtract(3, 'M').toDate();
            const end = dayjs().endOf('day').toDate();
            picker.$emit('pick', [start, end]);
        }
    }]
};
export const config = {
    name: 'costomerAuth',
    searchFields: [
        {
            name: '客户名称',
            value: 'customerName',
            type: 'input',
            isFixed: true,
            span: 3
        },
        {
            name: '客户编码',
            value: 'customerCode',
            type: 'input',
            isFixed: true,
            span: 3
        },
        {
            name: '客户系',
            value: 'customerGroupName',
            type: 'advance',
            isFixed: true,
            span: 3,
            advanceConfig: [
                { prop: 'customerGroupName', value: '客户系名称' },
                { prop: 'customerGroupCode', value: '客户系编码' },
                { prop: 'topCustomerGroupCode', value: 'TOP客户系编码' }
            ],
            tableConfig: [
                { prop: 'topCustomerGroupCode', value: 'TOP客户系编码' },
                { prop: 'customerGroupCode', value: '客户系编码' },
                { prop: 'customerGroupName', value: '客户系名称' }
            ],
            prefix: '/api-lcrm',
            advanceUrl: '/customer/group/info/page',
            cbParams: ['customerGroupCode', 'customerGroupName', 'topCustomerGroupCode'],
            advanceCode: 'advanceCode'
        },
        {
            name: '用户',
            value: 'crmUserCodes',
            type: 'advanceUser',
            isMultCheck: true,
            isFixed: true,
            span: 3
        },
        {
            name: '创建时间',
            value: 'createTime',
            isFixed: true,
            type: 'time',
            time: 'daterange',
            startPlaceholder: '创建时间从',
            endPlaceholder: '创建时间到',
            pickerOptions,
            span: 6
        },
        {
            name: '修改时间',
            value: 'updateTime',
            isFixed: true,
            type: 'time',
            time: 'daterange',
            startPlaceholder: '修改时间从',
            endPlaceholder: '修改时间到',
            pickerOptions,
            span: 6
        }
    ],
    selection: false,
    index: false,
    tableData: {
        columns: [
            {
                label: '客户名称',
                prop: 'customerName',
                minWidth: 150
            },
            {
                label: '客户编码',
                prop: 'customerCode',
                minWidth: 150
            },
            {
                label: '用户',
                prop: 'crmUserName',
                minWidth: 150
            },
            {
                label: '用户mip',
                prop: 'crmUserCode',
                minWidth: 150
            },
            {
                label: '创建人',
                prop: 'createUserName',
                minWidth: 150
            },
            {
                label: '创建人mip',
                prop: 'createUserCode',
                minWidth: 150
            },
            {
                label: '创建时间',
                prop: 'createTime',
                minWidth: 150
            },
            {
                label: '修改人mip',
                prop: 'updateUserCode',
                minWidth: 150
            },
            {
                label: '修改人名称',
                prop: 'updateUserName',
                minWidth: 150
            },
            {
                label: '修改时间',
                prop: 'updateTime',
                minWidth: 150
            }
        ]
    },
    PANELMAX: 8,
    LASTFOUR: 4,
    LASTSIX: 6
};
