import request from '@/utils/http';
const apiCrmHost = 'api-lcrm';

// 合同客户列表
export const getCustomerAuthInfoPage = (data) => {
    return request({
        url: apiCrmHost + '/customer/auth/info/page',
        method: 'post',
        data
    });
};
